'use client'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@design-system/src/components/Box'
import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Icon } from '@design-system/src/components/Icon'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { useWindowSize } from '@design-system/src/hooks/useWindowSize'
import { Cross1Icon, HamburgerMenuIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next-nprogress-bar'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import queryString from 'query-string'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useScrollDirection } from 'react-use-scroll-direction'
import { css, cva, cx } from 'styled-system/css'

import { DEFAULT_HEADER_MENU_LOGO_SIZE } from '@shared/constants'

import { arePublicationsAvailable, isUserSubscribedToPublication } from '../../../_utils/publication-utils'
import { BlockPubHeaderMenuCustomPageData, BlockViewProps } from '../../../_utils/typescript-utils'

let timerId: null | ReturnType<typeof setTimeout> = null

export const HeaderMain: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubHeaderMenuCustomPageData
  }>
> = props => {
  const {
    temperature = '',
    address = '',
    logo,
    logoHeight,
    logoWidth,
    mainMenu,
    drawerMenu1,
    drawerMenu2,
    drawerMenu3,
    drawerMenu4,
    longDate = '',
    shortDate = '',
    searchPageUrl,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
    logoSize = DEFAULT_HEADER_MENU_LOGO_SIZE,
    customCtaLabel,
    customCtaLink,
  } = props?.blockCustomData
  const pathname = usePathname()
  // const [isClient, setIsClient] = useState(true)
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [showSidenav, setShowSidenav] = useState<boolean>(false)
  const [mobileTop, setMobileTop] = useState<number>(0)
  const [webTop, setWebTop] = useState<number>(0)
  const sidenavMenuRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const appleInputRef = useRef<HTMLInputElement>(null)
  const navHeaderRef = useRef<HTMLDivElement>(null)
  const windowsWidth = useWindowSize()
  const router = useRouter()
  const { isScrollingUp, isScrollingDown } = useScrollDirection()
  const [headerSpacing, setHeaderSpacing] = useState<'small' | 'large'>('large')
  const showAppleSearch = !showSidenav && isSearchBarOpen
  const isSearchPage = pathname == '/search'
  const { data: session, status } = useSession()
  const drawerInputTextfieldRef = useRef<HTMLInputElement>(null)

  const customCtaLinkClick = useCallback(() => {
    if (customCtaLink) {
      if (customCtaLink.startsWith('/')) {
        router.push(customCtaLink)
      } else {
        window.open(customCtaLink, '_blank')
      }
    }
  }, [customCtaLink])

  // useEffect(() => {
  //   // Doing this to prevent this error: https://react.dev/errors/418
  //   // https://react.dev/errors/418
  //   setIsClient(true)
  // }, [])

  useEffect(() => {
    // It takes a second for fonts to swap etc which changes the scrollY value,
    // which changes the scroll direction. So removing changes to scrollDirection for
    // that period of time
    timerId = setTimeout(() => {
      timerId = null
    }, 1000)
    // router.prefetch(searchPageUrl)
    // router.prefetch('/account')
    router.prefetch('/subscribe')
  }, [])

  useEffect(() => {
    // Need to use a timerID because useScrollDirection uses scrollY to determine it's scrolling direction.
    // However, changing the headerSpacing affects the scrollY value, creating an infinite loop as when the
    // the headerSpacing becomes large then the direction goes DOWN, which triggers the headerspacing to be small
    // which in turn causes the direction to go UP
    if (!timerId && isScrollingUp && headerSpacing === 'small') {
      timerId = setTimeout(() => {
        timerId = null
      }, 501)
      setHeaderSpacing('large')
    } else if (!timerId && isScrollingDown && headerSpacing === 'large') {
      timerId = setTimeout(() => {
        timerId = null
      }, 501)
      setHeaderSpacing('small')
    }
  }, [isScrollingDown, isScrollingUp])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showSidenav && sidenavMenuRef.current && !sidenavMenuRef.current.contains(e.target as Node)) {
        if (e.target?.tagName === 'SPAN') return
        setShowSidenav(false)
        setIsSearchBarOpen(false)
        setSearchValue('')
      }
    }
    document.addEventListener('click', checkIfClickedOutside)

    return () => {
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [showSidenav])

  useEffect(() => {
    if (headerRef) headerRef.current && setMobileTop(headerRef.current.offsetHeight)
    if (navHeaderRef) navHeaderRef.current && setWebTop(navHeaderRef.current.offsetHeight)
  }, [headerRef, navHeaderRef, windowsWidth, showSidenav, headerSpacing])

  const onSearch = () => {
    router.push(`${searchPageUrl}?${queryString.stringify({ term: searchValue })}`)
  }

  const toggleSidenav = () => {
    const newSidenavState = !showSidenav
    setShowSidenav(newSidenavState)
    if (newSidenavState) {
      // Must used this instead of autofocus.
      // Otherwise, on mobile, a zoom will be automatically triggered, making the user have to scroll. (* Mobile browsers often zoom in on input fields when they receive focus)
      setTimeout(() => {
        const drawerInputRef = drawerInputTextfieldRef?.current?.querySelector('input')
        if (drawerInputRef) {
          drawerInputRef.focus()
        }
      }, 1)
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onSearch()
    setShowSidenav(false)
    setIsSearchBarOpen(false)
  }

  const focusOnSearchInput = useCallback(() => {
    setTimeout(() => {
      appleInputRef.current?.focus()
    }, 1)
  }, [])

  const userIsSubscribed = isUserSubscribedToPublication({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })

  const publicationsAreAvailable = arePublicationsAvailable({
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
  })

  const showCustomCtaButton = customCtaLabel && customCtaLink
  const showSubscribeButton = status !== 'loading' && publicationsAreAvailable && !userIsSubscribed
  const showAccountButton = status === 'authenticated'
  const accountButtonVariant = publicationsAreAvailable && !userIsSubscribed ? 'tertiary' : 'primary'
  const customCtaButtonVariant = showSubscribeButton || showAccountButton ? 'secondary' : 'primary'

  return (
    <Box
      data-sho-block-id={props.blockId}
      data-sho-block-name="BlockPubHeaderMenu-HeaderMain"
      ref={headerRef}
      css={{
        backgroundColor: '$gs1',
        position: 'sticky',
        top: '[0px]',
        zIndex: '[20]',
        // [`& ${Text}`]: { // TODO: commented when refactored with Panda, but not sure if it's necessary. Re-enable if needed, otherwise remove
        //   textTransform: 'capitalize',
        // },
      }}>
      <Box ref={navHeaderRef}>
        <Gutter variant="horizontal">
          <nav
            className={headerTopContainer({
              spacing: headerSpacing,
            })}>
            <CustomFlex justify="between" align="center">
              <Box
                css={{
                  width: '$full',
                  display: 'none',
                  bp2: {
                    display: 'block',
                  },
                }}>
                <Text variant="caption" css={{ color: '$gs11', width: '$fit' }}>
                  {longDate}
                </Text>
                <Text variant="caption" css={{ color: '$gs11', width: '$fit' }}>
                  <b>
                    {temperature && `${temperature}`}
                    {address && temperature ? ' / ' : ''}
                  </b>
                  {`${address}`}
                </Text>
              </Box>
              <CustomFlex css={{ width: '$full' }} justify="center">
                {logo && (
                  <Link href="/" prefetch={true}>
                    <ShoImage
                      className={shoImageStyled({
                        logoSize,
                        headerSpacing,
                      })}
                      src={logo}
                      width={logoWidth}
                      height={logoHeight}
                      alt="Logo Page Header"
                      priority={true}
                      sizes="(max-width: 56.5625em) 50vw, 400px"
                    />
                  </Link>
                )}
              </CustomFlex>
              <CustomFlex
                justify="end"
                align="center"
                css={{
                  display: 'none',
                  bp2: {
                    display: 'flex',
                  },
                }}>
                <>
                  {status === 'unauthenticated' && (
                    <Link href="/sign-in" prefetch={true}>
                      <Text
                        variant={'button'}
                        isLink={true}
                        css={{
                          color: '$pri',
                          px: '$2',
                          height: '$12',
                          display: 'inline !important',
                          alignItems: 'center',
                          minWidth: '$14',
                          _before: {
                            left: '$2',
                          },
                          _hover: {
                            _before: {
                              width: '[calc(100% - {sizes.$4})]',
                            },
                          },
                        }}>
                        Sign&nbsp;In
                      </Text>
                    </Link>
                  )}
                  {showAccountButton && (
                    <Button
                      variant={accountButtonVariant}
                      label="Account"
                      onClick={() => {
                        router.push('/account')
                      }}
                    />
                  )}
                  {showSubscribeButton && (
                    <Button
                      css={{ ml: '$2' }}
                      variant="primary"
                      label="Subscribe"
                      onClick={() => {
                        router.push('/subscribe')
                      }}
                    />
                  )}
                  {showCustomCtaButton && (
                    <Button
                      css={{ ml: '$2' }}
                      variant={customCtaButtonVariant}
                      label={customCtaLabel}
                      onClick={customCtaLinkClick}
                    />
                  )}
                </>
              </CustomFlex>
            </CustomFlex>
          </nav>
        </Gutter>
      </Box>

      <Gutter variant="horizontal" css={{ backgroundColor: showAppleSearch ? '$gs3' : '$gs1' }}>
        <CustomFlex
          className={headerBottomContainer({
            spacing: headerSpacing,
          })}
          justify="between"
          align="center"
          gap={'4'}>
          <Icon
            onClick={() => {
              toggleSidenav()
              setIsSearchBarOpen(false)
            }}
            reactIcon={showSidenav ? <Cross1Icon /> : <HamburgerMenuIcon />}
            className={itemToHideWhenAppleSearch({
              hideContent: showAppleSearch,
            })}
            css={{
              color: '$pri',
              userSelect: 'none',
              _hover: {
                opacity: 0.7,
                transitionProperty: '[opacity]',
                transitionDuration: '$normal',
                transitionTimingFunction: '[ease-in-out]',
              },
            }}
            pointer={true}
          />
          <CustomFlex
            align="center"
            css={{
              width: 'auto',
              marginRight: isSearchPage ? '[24px]' : '[0px]',
              bp2: { width: '[inherit]' },
            }}
            gap={'4'}>
            {/* FOR DESKTOP ONLY */}
            <>
              {mainMenu?.items?.length && (
                <ul className={mainMenuContainer}>
                  {mainMenu.items.map(item => (
                    <li key={item.id}>
                      {item.url === pathname || item.url === `${pathname}/` ? (
                        <Text
                          variant="body2"
                          className={itemToHideWhenAppleSearch({
                            hideContent: showAppleSearch,
                          })}
                          css={{
                            opacity: '[0.75]',
                            color: '$pri',
                            cursor: 'default',
                            whiteSpace: 'nowrap',
                            lineHeight: '[1em]',
                            _hover: {
                              color: '$pri',
                            },
                          }}>
                          {item.name}
                        </Text>
                      ) : item.url.startsWith('http') ? (
                        <a key={item.id} href={item.url} target="_blank" rel="noreferrer">
                          <Text
                            variant="body2"
                            className={itemToHideWhenAppleSearch({
                              hideContent: showAppleSearch,
                            })}
                            css={{
                              color: '$gs12',
                              whiteSpace: 'nowrap',
                              lineHeight: '[1em]',
                              _hover: {
                                color: '$gs11',
                              },
                            }}>
                            {item.name}
                          </Text>
                        </a>
                      ) : (
                        <Link href={item.url} prefetch={true}>
                          <Text
                            variant="body2"
                            className={itemToHideWhenAppleSearch({
                              hideContent: showAppleSearch,
                            })}
                            css={{
                              color: '$gs12',
                              whiteSpace: 'nowrap',
                              lineHeight: '[1em]',
                              _hover: {
                                color: '$gs11',
                              },
                            }}>
                            {item.name}
                          </Text>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {!isSearchPage && (
                <div
                  className={css({
                    bp2: {
                      width: '$auto',
                      zIndex: '[10]',
                      display: 'flex',
                    },
                  })}>
                  <CustomFlex align="center" justify="end">
                    <Icon
                      className={itemToHideWhenAppleSearch({
                        hideContent: showAppleSearch,
                      })}
                      reactIcon={<MagnifyingGlassIcon />}
                      pointer={true}
                      onClick={() => {
                        if (isSearchBarOpen) {
                          onSearch()
                        } else {
                          setIsSearchBarOpen(true)
                          focusOnSearchInput()
                        }
                      }}
                      css={{
                        color: '$pri',
                        _hover: {
                          opacity: 0.7,
                          transitionProperty: '[opacity]',
                          transitionDuration: '$normal',
                          transitionTimingFunction: '[ease-in-out]',
                        },
                      }}
                    />
                  </CustomFlex>
                  <form
                    onSubmit={handleSubmit}
                    className={appleSearchContainer({
                      displaySearch: showAppleSearch,
                    })}>
                    <Icon
                      reactIcon={<MagnifyingGlassIcon />}
                      size={20}
                      className={searchIcon({
                        hasSearchValue: !!searchValue,
                      })}
                      onClick={handleSubmit}
                      disabled={!searchValue}
                    />
                    <input
                      className={cx(
                        css({
                          txtStyle: 'body2',
                          fontSize: '[clamp(16px, token(fontSizes.$body2), 100%)]',
                          // fontSize: '[clamp(16px, var(--font-sizes-$body2), 100%)]',
                          pl: '$2',
                          flexGrow: 2,
                          borderWidth: '$0',
                          backgroundColor: 'transparent',
                          _focus: {
                            outline: 'none',
                          },
                        }),
                      )}
                      ref={appleInputRef}
                      name="search"
                      placeholder="Enter Search"
                      value={searchValue}
                      onChange={(e: { target: { value: string } }) => setSearchValue(e.target.value)}
                      autoFocus={true}
                      autoComplete="off"
                      type="text"
                    />

                    <Icon
                      reactIcon={<Cross1Icon />}
                      size={16}
                      pointer={true}
                      onClick={() => {
                        setIsSearchBarOpen(false)
                        setSearchValue('')
                      }}
                      css={{
                        color: '$gs12',
                        transitionProperty: 'transform',
                        transitionDuration: '$normal',
                        transitionTimingFunction: '[ease-out]',
                        padding: '$1',
                        _hover: {
                          transform: 'scale(1.1)',
                          transitionTimingFunction: '[ease-in]',
                        },
                      }}
                    />
                  </form>
                </div>
              )}
            </>

            {/* FOR TABLET AND MOBILE ONLY */}
            <>
              <CustomFlex
                justify="center"
                align="center"
                css={{
                  position: 'absolute',
                  maxWidth: '$full',
                  overflowY: 'auto',
                  width: 'fit',
                  transform: 'translateX(-50%)',
                  left: '[50%]',
                  bp2: {
                    display: 'none',
                  },
                }}>
                {status === 'authenticated' && userIsSubscribed && (
                  <>
                    <Text
                      oll={true}
                      variant="caption"
                      css={{
                        color: '$gs12',
                        textAlign: 'center',
                        display: 'none',
                        bp1: { display: 'block' },
                      }}>
                      {longDate}
                    </Text>
                    <Text
                      oll={true}
                      variant="caption"
                      css={{
                        color: '$gs12',
                        textAlign: 'center',
                        display: 'block',
                        bp1: { display: 'none' },
                      }}>
                      {shortDate}
                    </Text>
                  </>
                )}
              </CustomFlex>

              <CustomFlex
                justify={status === 'authenticated' && userIsSubscribed ? 'end' : 'start'}
                gap={'4'}
                align="center"
                css={{
                  width: '$full',
                  display: showAppleSearch ? 'none !important' : 'flex',
                  bp2: {
                    display: 'none',
                  },
                }}>
                {status === 'unauthenticated' && (
                  <Link href="/sign-in" prefetch={true}>
                    <Text
                      variant="button"
                      isLink={true}
                      css={{
                        color: '$pri',
                        // minWidth: '$14',
                      }}>
                      Sign&nbsp;In
                    </Text>
                  </Link>
                )}
                {status === 'authenticated' && (
                  <Link href="/account" prefetch={true}>
                    <Text
                      variant="button"
                      css={{
                        color: '$pri',
                        py: '$2',
                        '&:hover': {
                          opacity: '0.85',
                          transitionProperty: 'opacity',
                          transitionDuration: '$fast',
                          transitionTimingFunction: 'linear',
                        },
                      }}>
                      Account
                    </Text>
                  </Link>
                )}
                {showSubscribeButton && (
                  <Box css={{ width: '$full' }}>
                    <Button
                      variant="primary"
                      label="Subscribe"
                      fullWidth={false}
                      onClick={() => {
                        router.push('/subscribe')
                      }}
                    />
                  </Box>
                )}
                {showCustomCtaButton && (
                  <Box css={{ width: '$full' }}>
                    <Button
                      variant={customCtaButtonVariant}
                      label={customCtaLabel}
                      fullWidth={false}
                      onClick={customCtaLinkClick}
                    />
                  </Box>
                )}
              </CustomFlex>
            </>
          </CustomFlex>
        </CustomFlex>
        <Box
          css={{
            height: '[1px]',
            paddingTop: '$1',
            display: 'block',
            borderBottomWidth: '$1',
            borderBottomStyle: 'solid',
            borderBottomColor: '$gs6',
          }}
        />
      </Gutter>
      <div
        className={overlay({
          isVisible: showSidenav,
        })}
        onClick={() => setShowSidenav(false)}
      />
      <div
        className={drawer({
          showSidenav: showSidenav,
        })}
        ref={sidenavMenuRef}>
        <div className={drawerContentContainer}>
          {!isSearchPage && (
            <form onSubmit={handleSubmit} className={drawerForm}>
              <CustomFlex className={searchInput}>
                <CustomFlex align="center" css={{ bgColor: '$pri' }}>
                  <Icon
                    reactIcon={<MagnifyingGlassIcon />}
                    css={{ color: '$priText' }}
                    pointer={true}
                    onClick={onSearch}
                  />
                  {showSidenav && (
                    <>
                      <Textfield
                        name="search"
                        css={{
                          borderRadius: '$0',
                          color: '$gs1',
                          height: '$8',
                          _focus: {
                            borderBottomWidth: '$1',
                            borderBottomStyle: 'solid',
                            borderBottomColor: '$gs1',
                          },
                          '& input': {
                            color: '$gs1',
                          },
                          '& input::placeholder': {
                            color: '$gs4',
                          },
                        }}
                        ref={drawerInputTextfieldRef}
                        placeholder="Enter Search"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        fullWidth={true}
                        type="text"
                      />
                    </>
                  )}
                </CustomFlex>
              </CustomFlex>
            </form>
          )}
          <div className={menuDrawerContainer}>
            {[drawerMenu1, drawerMenu2, drawerMenu3, drawerMenu4].map((menu, index) => {
              if (menu?.items?.length) {
                return (
                  <Box key={index}>
                    {menu.name && (
                      <Text variant="h6" css={{ mb: '$2' }}>
                        {menu.name}
                      </Text>
                    )}
                    <CustomFlex direction="column" gap="1">
                      {menu.items.map(item =>
                        item.url === pathname || item.url === `${pathname}/` ? (
                          <Text
                            key={item.id}
                            variant="body2"
                            className={itemToHideWhenAppleSearch({
                              hideContent: showAppleSearch,
                            })}
                            css={{
                              opacity: '[0.75]',
                              color: '$w1',
                              textTransform: 'capitalize',
                              width: '$fit',
                            }}>
                            {item.name}
                          </Text>
                        ) : item.url.startsWith('http') ? (
                          <a key={item.id} href={item.url} target="_blank" rel="noreferrer">
                            <Text
                              variant="body2"
                              css={{ color: '$w1', textTransform: 'capitalize', width: '$fit', py: '[2px]' }}
                              isLink={true}>
                              {item.name}
                            </Text>
                          </a>
                        ) : (
                          <Link key={item.id} href={item.url} prefetch={true}>
                            <Text
                              variant="body2"
                              css={{ color: '$w1', textTransform: 'capitalize', width: '$fit', py: '[2px]' }}
                              isLink={true}>
                              {item.name}
                            </Text>
                          </Link>
                        ),
                      )}
                    </CustomFlex>
                  </Box>
                )
              }
            })}
          </div>
        </div>
        {/* {drawerMenu3.length > 0 && (
            <Box css={{ mt: '$7' }}>
              <Text variant="subtitle1" css={{ mb: '$2' }}>
                Connect
              </Text>
              <CustomFlex>gap={3}>
                {drawerMenu3.map(el => (
                  <SocialMediaIcon key={el.link} link={el.link} type={el.text.toLowerCase()} />
                ))}
              </CustomFlex>
            </Box>
          )} */}
      </div>
    </Box>
  )
}

const searchIcon = cva({
  base: {
    borderRadius: '$1',
    padding: '[2px]',
    transitionProperty: '[all]',
    transitionDuration: '$fast',
    transitionTimingFunction: 'linear',
    borderWidth: '$1',
    borderStyle: 'solid',
    _hover: {
      opacity: 0.7,
      transitionProperty: '[opacity]',
      transitionDuration: '$normal',
      transitionTimingFunction: 'in-out',
    },
  },
  variants: {
    hasSearchValue: {
      true: {
        color: '$pri',
        borderColor: '$pri',
      },
      false: {
        color: '$gs12',
        borderColor: 'transparent',
      },
    },
  },
  defaultVariants: {
    hasSearchValue: false,
  },
})

const itemToHideWhenAppleSearch = cva({
  variants: {
    hideContent: {
      true: {
        opacity: '0',
        transition: '[all {durations.$normal} ease 0s]',
        zIndex: '$hidden',
      },
      false: {},
    },
  },
  defaultVariants: {
    hideContent: false,
  },
})

const drawerForm = css({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'flex-end',
  px: '$6',
  pt: '$10',
  pb: '$0',
  bp1: {
    pt: '$10',
    px: '$10',
  },
  bp2: { display: 'none' },
})

const menuDrawerContainer = css({
  flex: '[1 1 0%]',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  p: '$6',
  bp1: { p: '$10' },
  bp2: { gap: '$8' },
  bp3: { gap: '$10' },
  _scrollbar: {
    display: 'none',
  },
})

const drawerContentContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  boxSizing: 'border-box',
  width: '$full',
  height: '$full',
})

const overlay = cva({
  base: {
    position: 'fixed',
    inset: '$0',
  },
  variants: {
    isVisible: {
      true: {
        zIndex: '[100]',
        backgroundColor: '$b6',
        transitionProperty: '[all]',
        transitionDuration: '$normal',
        transitionTimingFunction: '[ease-in-out]',
      },
      false: {
        zIndex: '[-1]',
        display: 'none',
        backgroundColor: '$b12',
      },
    },
  },
  defaultVariants: {
    isVisible: false,
  },
})

const drawer = cva({
  base: {
    boxSizing: 'border-box',
    top: '$0',
    bottom: '$0',
    zIndex: '[101]',
    position: 'fixed',
    backgroundColor: '$pri',
    overflowY: 'scroll',
    color: '$gs1',
    transitionProperty: '[left]',
    transitionDuration: '$normal',
    width: '[min(80vw,400px)]',
  },
  variants: {
    showSidenav: {
      true: {
        left: '$0',
      },
      false: {
        transitionProperty: '[left]',
        transitionDuration: '$normal',
        left: '[-1000px]',
        bp1: {
          left: '[-400px]',
        },
      },
    },
  },
})

const headerTopContainer = cva({
  base: {
    position: 'relative',
    justifyContent: 'space-between',
    borderBottomWidth: '$1',
    borderBottomStyle: 'solid',
    borderBottomColor: '$gs6',
  },
  variants: {
    spacing: {
      small: {
        py: '$1',
        // transition: '500ms ease-in-out',
      },
      large: {
        py: '$4',
        // transition: '500ms ease-in-out',
      },
    },
  },
  defaultVariants: {
    spacing: 'large',
  },
})
// Flex
const headerBottomContainer = cva({
  base: {
    overflow: 'hidden',
    position: 'relative',
    borderBottomWidth: '$1',
    borderBottomStyle: 'solid',
    borderBottomColor: '$gs6',
    '& ul': {
      display: 'flex',
      listStyle: 'none',
    },
    '& li': {
      cursor: 'pointer',
    },
    '& a.subscribe': {
      width: '$full',
    },
  },
  variants: {
    spacing: {
      small: {
        py: '$1',
      },
      large: {
        py: '$2',
      },
    },
  },
  defaultVariants: {
    spacing: 'large',
  },
})

const searchInput = css({
  alignItems: 'center',
  background: '$gs1',
  height: '$8',
  width: '$full',
  backgroundColor: '$pri',
  '&  div': {
    borderBottomWidth: '[0px !important]',
  },
  '& input': {
    backgroundColor: '$pri',
    p: '$0',
    pl: '$2',
    height: '$8',
    textTransform: 'none',
    transitionDuration: '$normal',
    transitionProperty: 'border-bottom-color',
    borderBottomWidth: '$1',
    borderBottomStyle: 'solid',
    borderBottomColor: '$gs8',
    _focus: {
      borderBottomColor: '$gs1',
      outline: 'none',
    },
  },
})

const mainMenuContainer = css({
  display: 'none !important',
  listStyle: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: '$2',
  columnGap: '$3',
  flex: '[1 1 0%]',
  flexWrap: 'wrap',
  bp2: {
    display: 'flex !important',
  },
})

const appleSearchContainer = cva({
  variants: {
    displaySearch: {
      true: {
        display: 'flex',
        position: 'absolute',
        inset: '[50% 0 0 50%]',
        transform: 'translate(-50%, -50%)',
        maxWidth: '[600px]',
        alignItems: 'center',
        zIndex: '[1]',
        opacity: 1,
        transition: '[opacity {durations.$normal} linear {durations.$normal}]',
        '& > *': {
          width: 'auto',
        },
      },
      false: {
        display: 'none',
        zIndex: '$hidden',
        opacity: 0,
        '& > *': {
          width: '$0',
        },
      },
    },
  },
  defaultVariants: {
    displaySearch: false,
  },
})

const shoImageStyled = cva({
  base: {
    width: 'auto',
    transitionProperty: 'max-height',
    transitionDuration: '$normal',
    transitionTimingFunction: 'in-out',
    _hover: {
      opacity: '0.8',
      transitionTimingFunction: 'in-out',
    },
  },
  variants: {
    logoSize: {
      small: {},
      medium: {},
      large: {},
    },
    headerSpacing: {
      small: {},
      large: {},
    },
  },
  compoundVariants: [
    {
      logoSize: 'small',
      headerSpacing: 'small',
      css: {
        height: '[16px !important]',
        bp2: {
          height: '[27px !important]',
        },
        bp3: {
          height: '[32px !important]',
        },
      },
    },
    {
      logoSize: 'small',
      headerSpacing: 'large',
      css: {
        height: '[16px !important]',
        bp2: {
          height: '[27px !important]',
        },
        bp3: {
          height: '[32px !important]',
        },
      },
    },
    {
      logoSize: 'medium',
      headerSpacing: 'small',
      css: {
        height: '[20px !important]',
        bp2: {
          height: '[30px !important]',
        },
        bp3: {
          height: '[45px !important]',
        },
      },
    },
    {
      logoSize: 'medium',
      headerSpacing: 'large',
      css: {
        height: '[24px !important]',
        bp2: {
          height: '[35px !important]',
        },
        bp3: {
          height: '[54px !important]',
        },
      },
    },
    {
      logoSize: 'large',
      headerSpacing: 'small',
      css: {
        height: '[26px !important]',
        bp2: {
          height: '[40px !important]',
        },
        bp3: {
          height: '[60px !important]',
        },
      },
    },
    {
      logoSize: 'large',
      headerSpacing: 'large',
      css: {
        height: '[30px !important]',
        bp2: {
          height: '[50px !important]',
        },
        bp3: {
          height: '[90px !important]',
        },
      },
    },
  ],
  defaultVariants: {
    logoSize: DEFAULT_HEADER_MENU_LOGO_SIZE,
  },
})
