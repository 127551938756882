'use client'

import { Box } from '@design-system/src/components/Box'
import { Gutter } from '@design-system/src/components/Gutter'
import { PdfViewer } from '@design-system/src/components/PdfViewer/index'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { BlockPubArticleHeaderCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC, useEffect, useRef } from 'react'
import { css, cx } from 'styled-system/css'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperOptions } from 'swiper/types'

import { arrowStyle, swiperInjectedStyles } from './ArticleCarousel'

// declare Swiper custom elements
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'swiper-slide': any
      'swiper-container': any
    }
  }
}

type SwiperRef = HTMLElement & { swiper: Swiper; initialize: () => void }

type ArticleCarouselProps = {
  carousel: NonNullable<BlockPubArticleHeaderCustomPageData['carousel']>
  initialSlide: number
}

export const EReaderCarousel: FC<ArticleCarouselProps> = ({ carousel, initialSlide }) => {
  const swiperRef = useRef<SwiperRef>(null)

  useEffect(() => {
    const intiateSwiper = async () => {
      const register = await import('swiper/element').then(mod => mod.register)
      //https://swiperjs.com/blog/using-swiper-element-in-react
      // Register Swiper web component
      register()

      // Object with parameters
      const params: SwiperOptions = {
        modules: [Navigation],
        slidesPerView: 1,
        pagination: false,
        loop: true,
        initialSlide,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        injectStyles: [swiperInjectedStyles],
      }

      if (swiperRef && swiperRef.current) {
        // Assign it to swiper element
        Object.assign(swiperRef.current, params)
        // initialize swiper
        swiperRef.current.initialize()
      }
    }
    intiateSwiper()
  }, [])

  return (
    <>
      <swiper-container init="false" ref={swiperRef} style={{ height: '100dvh' }}>
        {carousel.map((slide, index) => {
          const isSlideFileAPdf = slide.key.endsWith('.pdf')

          return (
            <swiper-slide key={index}>
              <Box
                css={{
                  background: '$gs12',
                  width: '$full',
                  // height: '$full',
                }}>
                <Gutter variant="bare" css={{ position: 'absolute', inset: '$0' }}>
                  {isSlideFileAPdf ? (
                    <div
                      className={css({
                        position: 'relative',
                        height: '$full',
                      })}>
                      <PdfViewer
                        assetKey={slide.key}
                        className={css.raw({
                          position: 'absolute',
                          inset: '[0px]',
                          width: '$full',
                          height: '$full',
                        })}
                      />
                    </div>
                  ) : (
                    <ShoImage
                      alt={slide.caption || `story image illustation`}
                      fill={true}
                      src={slide.key}
                      blurryPlaceholder={slide.blurryPlaceholder}
                      fillWrapperCss={css.raw({
                        height: '$full',
                        width: '$full',
                      })}
                      imageCss={css.raw({
                        objectFit: 'contain',
                      })}
                      priority={index === 0}
                      sizes="(min-width: 77.5em) 1200px, 100vw"
                    />
                  )}
                </Gutter>
              </Box>
            </swiper-slide>
          )
        })}
      </swiper-container>
      <div
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className={cx(arrowStyle({ direction: 'left', isVisible: carousel.length > 1 }), 'swiper-button-prev')}
      />
      <div
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className={cx(arrowStyle({ direction: 'right', isVisible: carousel.length > 1 }), 'swiper-button-next')}
      />
    </>
  )
}
